import React from 'react';
import { PageProps } from 'gatsby';

export default function Breadcrumb(props: { itemsArray: (string | null | undefined)[][], classNameList?: string, className?: string, location: PageProps['location'] } ) {
    
    const separator = " » ";
    const classNameList = props.classNameList ? props.classNameList : '';
    const className = props.className ? props.className : '';

    const BreadcrumbList = () => {
        let breadcrumbArray: JSX.Element[] = []; // Results to push
        const items = Object.values(props.itemsArray); // Getting parameters items
        const pathLength = items.length; 
        let index = 0;
        const domain = props.location.origin;

        // Home 
        const homePath = (
            <li className='breadcrumb__item' key='home'>
                <a className='breadcrumb__link' href={domain}>Accueil</a>
                <span className='breadcrumb__separator' aria-hidden="true">{separator}</span>
            </li>
        )
        breadcrumbArray.push(homePath);

        // Others pages
        if(items) {
            for(index ; index < pathLength ; index++) {
                const itemUrl = items[index][0];
                const itemLabel = items[index][1];
                let breadcrumbItem: JSX.Element;
                
                if(items[index + 1]) { 
                    breadcrumbItem = (
                        <li className='breadcrumb__item' key={index}>
                            <a className='breadcrumb__link' href={`${domain}${itemUrl}`}>{itemLabel}</a>
                            <span className='breadcrumb__separator' aria-hidden="true">{separator}</span>
                        </li>
                    )
                } else {
                    breadcrumbItem = (
                        <li className={`breadcrumb__item breadcrumb__link__active`} key={index}>
                            <a className='breadcrumb__link' aria-current="page" href={`${domain}${itemUrl}`}>{itemLabel}</a>
                        </li>
                    )
                }
                
                breadcrumbArray.push(breadcrumbItem);
            }
        }
        return (
            <>
                {breadcrumbArray}
            </>
        );
    }
  
    return (
        <div className={`breadcrumb ${className}`}>
            <ul className={`breadcrumb__list ${classNameList}`}>
                <BreadcrumbList />
            </ul>
        </div>
    )
}