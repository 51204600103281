import React from 'react';
import { Link, PageProps } from 'gatsby';
import Breadcrumb from '../components/Layout/breadcrumb';
import { Col, Row, Container, Accordion, Button} from 'react-bootstrap';

import Layout from '../components/Layout/layout';
import Meta from '../components/Layout/head';
import validateURL from '../components/security/validate-url';

import media from '../images/default-social-share.jpg';

export const Head = () => (
    <Meta 
        title={`FAQ`} 
        description={`Si vous vous posez une quelconque question à propos de Rheaparks, c'est par ici !`}
        lang='fr'
        pathname="/faq/"
        media={`https://www.rheaparks.com${media}`}
    >
    </Meta> 
)

export default function FAQ({ location }: PageProps) {

    // Open one specific section with url parameter
    const isBrowser = () => typeof window !== "undefined"; // Detection needed for gatsby build

    // Get Url search parameters
    const params = isBrowser() ? new URLSearchParams(location.search) : null;
    const paramsA = params?.get('a');
    let activeKey = "0";
    
    if(typeof paramsA === "string") {
        activeKey = paramsA;
    }

    const breadcrumbArray = [['/faq/','FAQ']]; // Breadcrumb

    return (
        <Layout>
            <Container className='green pt-2'>
                <Row>
                    <Col className='my-0' lg={{span:8,offset:2}} sm={12}>
                        <Breadcrumb location={location} itemsArray={breadcrumbArray} />
                        <div className='text-center bg-light-yellow rounded-md py-5 px-2'>
                            <h1>FAQ : La réponse à vos questions</h1>
                            <p>Vous avez une question sur Rheaparks ? Nous allons essayer d'y répondre du mieux que possible.</p> 
                        </div>
                        <div className='my-4'>
                            <h2>Projet</h2>
                            <Accordion defaultActiveKey={activeKey}>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header as="h3">💡 Qu’est-ce que Rheaparks ?</Accordion.Header>
                                    <Accordion.Body>
                                        <p>Rheaparks vous aide à planifier vos meilleures voyages au cœur des plus beaux endroits sur terre. 
                                            C’est un excellent guide de randonnée sur le sentier. Et une app faite avec beaucoup de soin qui respecte votre vie privée. 
                                            Elle permet d’explorer et découvrir les parcs naturels, entre faune et flore. On espère que vous allez aimer l’utiliser !</p>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="1">
                                    <Accordion.Header as="h3">🧑‍💻👩‍💻 Qui êtes-vous ?</Accordion.Header>
                                    <Accordion.Body>
                                        <p>Hello ! Nous c’est Sandra et Clément et nous sommes les cofondateurs de Rheaparks. 
                                            On vit actuellement en France à Montpellier. Il y a quelques années, nous avons fait un voyage magnifique dans les Alpes italiennes 
                                            et on y était si heureux que l’on a décidé de faire une app pour partager notre appréciation de la nature. 
                                            Depuis, on continue les multiples voyages au cœur des parcs naturels. 😉</p>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="2">
                                    <Accordion.Header as="h3">🗳 Comment sélectionnez-vous les randonnées présentées dans Rheaparks ?</Accordion.Header>
                                    <Accordion.Body>
                                        <p>Notre but avec Rheaparks est de faire découvrir les réserves naturelles et tous les magnifiques paysages qu’il y a à y découvrir, en tout respect de la nature. 
                                            Sandra passe beaucoup de temps à sélectionner soigneusement les randonnées à ajouter, et à écrire un guide pour chacune d’elles. 
                                            Et ce, notamment via l’aide des offices de tourisme. </p>
                                    
                                        <p>Pour chaque parc nous voulons offrir des randonnées de tous niveaux, longues, courtes, avec des paysages variés et inoubliables. 
                                            Peu importe le type de randonnée nous faisons de notre mieux pour en sélectionner qui soient belles et qui en valent la peine (et par peine on parle généralement d’effort physique 🥾😄). 
                                            Notre but est que le temps que vous passerez sur le chemin vous créera de fantastiques souvenirs.</p>

                                        <p>Aujourd’hui nous nous basons en premier sur la réputation et la popularité d’une randonnée. 
                                            On essaie de maintenir un savant équilibre entre beauté du paysage et intensité physique nécessaire pour y accéder. 
                                            Mais comme dit précédemment, on inclut un peu de tout afin que tout le monde puisse y trouver son compte.</p>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="3" id="classement">
                                    <Accordion.Header as="h3">🏆 Comment mesurez-vous le classement des randonnées ? </Accordion.Header>
                                    <Accordion.Body>
                                        <p>Le classement des randonnées est présenté à titre purement indicatif et à prendre avec des pincettes. Celui-ci prend en compte ces deux critères principaux : </p>
                                    
                                        <ul>
                                            <li><b>La beauté du lieu :</b> Points de vue, points d’intérêts, etc. que ce soit un panorama, une cascade, 
                                            un lac, un glacier ou autre, nous prenons en compte l’expérience qu’offre la randonnée.</li>
                                            <li><b>La popularité de la randonnée :</b> Lieu populaire, particulièrement apprécié et à ne pas manquer lors de vacances dans la destination.</li>
                                        </ul>

                                        <p>Ce que nous ne prenons pas en compte :</p>

                                        <ul>
                                            <li><b>Le type de randonnée :</b> boucle ou aller-retour. Nous essayons au maximum de proposer des boucles lorsque cela est accessible au plus grand nombre. 
                                            Cependant, certaines randonnée aller-retour mènent au cœur de splendides lieux qu’il serait vraiment dommage de manquer. 
                                            Ça reste donc pour nous un critère mineur. </li>
                                            <li><b>La sportivité :</b> Les plus sportifs aimeront des randonnées difficiles à fort dénivelé sur une courte distance. 
                                            Par souci d’accessibilité, nous ne prenons pas ce critère spécifique en compte. Il faut savoir que les randonnées les plus populaires 
                                            (sauf exception à plusieurs étapes) sont généralement les randonnées accessibles au plus grand nombre. 
                                            La difficulté, le dénivelé et plus généralement le descriptif vous donneront la puce à l’oreille en ce qui concerne la difficulté.</li>
                                        </ul>

                                        <p><b>Surtout, optez toujours pour des randonnées dont la difficulté vous est accessible, afin de ne pas vous mettre en danger (ou vos proches). 
                                            Et respectez la nature. 🌿</b></p>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="3" id="chiens">
                                    <Accordion.Header as="h3">🐕 Comment déterminez-vous si la présence de mon chien est autorisée ?</Accordion.Header>
                                    <Accordion.Body>
                                        <p>Alors la réglementation est plutôt claire :</p>
                                        <h4>Pour la plupart des parcs nationaux (sauf exception) :</h4>
                                        <ul>
                                            <li>Les chiens sont interdits dans le cœur du parc</li>
                                            <li>Autorisés en laisse uniquement dans la zone d’adhésion</li>
                                            <li>Hors du parc, pas de souci.</li>
                                        </ul>

                                        <h4>Pour la plupart des parc régionaux :</h4>
                                        <ul>
                                            <li>Chiens autorisés en laisse (sauf exceptions indiquées dans l’app).</li>
                                        </ul>

                                        <h4>Pour la plupart des réserves naturelles :</h4>
                                        <ul>
                                            <li>Chiens interdit au cœur des réserves.</li>
                                        </ul>

                                        <p>Les données sont indiquées en fonction des réglementations de chacun des parcs. En cas de doute, nous contactons les offices du tourisme.</p>

                                        <h4>Pourquoi indiquons nous « Chiens déconseillés » ?</h4>
                                        <p>Les chiens sont déconseillés sur certaines randonnées pour de multiples raisons :</p>
                                        <ul>
                                            <li>Forte probabilité de présence de patou</li>
                                            <li>Passages délicats et difficilement accessibles aux chiens.</li>
                                        </ul>
                                        <p>Tout a été recherché et ajouté avec le plus d’attention possible. Mais les réglementations peuvent changer, donc nous ne pouvons promettre à 100% l’exactitude de 100% des données indiquées.</p>
                                        <p>S’il y a une coquille, nous avons tout mis à disposition pour que les utilisateurs puissent remonter l’info !</p>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </div>
                        <div className='my-4'>
                            <h2>Photographies</h2>
                            <Accordion>
                                <Accordion.Item eventKey="4">
                                    <Accordion.Header as="h3">🏞 D’où viennent les images ?</Accordion.Header>
                                    <Accordion.Body>
                                        <p>Une petite partie sont des photos de nos propres voyages, mais la majorité viennent de notre communauté de créateurs ! 
                                            Vous pouvez même <Link to="/contact/">soumettre les vôtres</Link>. Nous avons beaucoup de chance d’avoir reçu la permission d’afficher le travail de nombreux randonneurs 
                                            et photographes talentueux et nous leur en sommes très reconnaissants. C’est pourquoi nous prenons en compte leurs droits de manière très sérieuse. 
                                            Vous pouvez trouver une mention et un lien sous chaque photo. Que ce soit sur le site, ou sur l’app. 
                                            Et la plupart du temps ce lien mène directement vers le compte Instagram du photographe. 
                                            Ce qui vous permet de découvrir leurs autres photos et voyages et de vous y abonner si vous aussi vous appréciez leur art !</p>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="5">
                                    <Accordion.Header as="h3">🧹 Comment est-ce que je peux supprimer une photo de Rheaparks ?</Accordion.Header>
                                    <Accordion.Body>
                                        <p>En plus de créditer de manière appropriée les photographes, nous sommes résolus à ne seulement afficher leur travail dans leurs propres termes. 
                                            Si vous êtes propriétaire des droits d’une image affichée sur Rheaparks, vous pouvez <Link to="/contact/">nous contacter</Link> 
                                            à tout moment pour demander son retrait. Nous traiterons votre requête dès que possible.</p>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="6">
                                    <Accordion.Header as="h3">📸 J’ai des belles photos d’une randonnée, est-ce que je peux vous les envoyer ?</Accordion.Header>
                                    <Accordion.Body>
                                        <p>Bien sûr ! <Link to="/contact/">Envoyez-nous simplement un message</Link> et nous considérerons de les ajouter ! 🥰</p>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion> 
                        </div>
                        <div className='my-4'>
                            <h2>Application / Site web</h2>
                            <Accordion>
                                <Accordion.Item eventKey="7">
                                    <Accordion.Header as="h3">💶 Est-ce que l’application Rheaparks est gratuite ?</Accordion.Header>
                                    <Accordion.Body>
                                        <p>Oui ! Absolument, et nous avons des fonctionnalités qui sont typiquement seulement disponibles derrière un abonnement
                                            "Premium" tel que le mode hors-ligne.</p>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="8">
                                    <Accordion.Header as="h3">👩‍💻 Est-ce que l'app Rheaparks marche sans connexion internet ?</Accordion.Header>
                                    <Accordion.Body>
                                        <p>L'application Rheaparks fonctionne en hors-ligne pour la plupart de son contenu, gratuitement et sans que vous n’ayez rien à faire.  
                                            C’est une fonctionnalité indispensable lorsque l’on est sur les chemins de randonnée !</p>
                                        <p>L’app est pour le moment dans ses débuts et il peut tout de même y avoir des problèmes. 
                                            Si vous en rencontrez, n’hésitez pas à <Link to="/contact/">nous contacter</Link>.</p>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="9">
                                    <Accordion.Header as="h3">📝 J’ai remarqué un problème dans le contenu (faute d’orthographe, imprécision, mauvais chemin)</Accordion.Header>
                                    <Accordion.Body>
                                        <p>Si vous avez trouvé quoi que ce soit d’insatisfaisant dans notre contenu, que ce soit une faute d’orthographe une imprécision ou 
                                            si vous pensez que le chemin que vous avez suivi dans l’app n’est pas correct, n’hésitez pas à <Link to="/contact/">nous contacter</Link>. 
                                            Nous ferons de notre mieux pour corriger le problème.</p>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="10">
                                    <Accordion.Header as="h3">🐛 J’ai remarqué un bug</Accordion.Header>
                                    <Accordion.Body>
                                        <p>Oh non ! C’est toujours mignon de croiser des insectes sur le chemin, mais dans l’app ou le site web on n’en veut pas ! 
                                            Merci de nous le faire savoir en <Link to="/contact/">nous contactant</Link> 🙂</p>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="11">
                                    <Accordion.Header as="h3">➕ Il y a quelque chose qui manque… Est-ce que je peux vous suggérer une fonctionnalité ?</Accordion.Header>
                                    <Accordion.Body>
                                        <p>Envoyez-nous simplement <Link to="/contact/">un email</Link>. 
                                            On ne peut pas vous promettre qu’on l’ajoutera parce que ça prend évidemment du temps et on a déjà plein d’améliorations de prévues. 
                                            Mais on sera heureux de l’entendre et on le prendra absolument en compte pour nos prochains développements.</p>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="12">
                                    <Accordion.Header as="h3">📱 Quand est-ce que Rheaparks arrivera sur Android ?</Accordion.Header>
                                    <Accordion.Body>
                                        <p>Quand nous aurons le temps et les fonds pour le développement. 
                                            Clément est un développeur iOS et il a donc commencé à coder sur la plateforme qu’il connait le mieux. 
                                            Nous voulons que tout le monde puisse utiliser Rheaparks et nous espérons pouvoir apporter l’application sur Android un jour.</p>
                                    
                                        <p>En attendant, le site web est là même sur mobile pour vous guider sur la route et prévoir votre prochaine aventure.</p>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="13">
                                    <Accordion.Header as="h3">💌 J’adore votre app, comment est-ce que je peux vous aider ?</Accordion.Header>
                                    <Accordion.Body>
                                        <p>On est si heureux que ce soit le cas ! (et nous espérons très fort que cette section soit pertinente pour quelqu’un 😄)</p>
                                        <p>La meilleure façon de nous aider est de partager Rheaparks autour de vous et/ou de noter l’app sur l’App Store. 
                                            Mais sinon on est juste très heureux que vous l’appréciez !</p>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>

                        </div>
                        <div className='my-4'>
                            <h2>Partenariats</h2>
                            <Accordion>
                                <Accordion.Item eventKey="14">
                                    <Accordion.Header as="h3">🤝 Est-ce que vous êtes ouverts à du sponsoring, des partenariats et autres opportunités publicitaires ?</Accordion.Header>
                                    <Accordion.Body>
                                        <p>Oui ! Nous sommes ouverts aux partenariats dans tous univers liés aux voyages et aux activités en nature.</p>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </div>
                        <div className='my-4'>
                            <h2>La suite</h2>
                            <Accordion>
                                <Accordion.Item eventKey="15">
                                    <Accordion.Header as="h3">🏔 Quels sont les prochains parcs que vous allez ajouter ?</Accordion.Header>
                                    <Accordion.Body>
                                        <p>On a une petite liste de parcs très importants qu’il nous reste à ajouter en France. 
                                            À moyen-terme, on se penchera sur des parcs naturels particulièrement appréciés en Europe. 🇫🇷🇪🇺</p>

                                        <p>Si vous travaillez dans un parc national / régional, une réserve naturelle, 
                                            une collectivité locale ou toute administration territoriale (que ce soit en France ou dans le reste du monde), 
                                            nous serions plus qu’heureux de vous aider à promouvoir votre territoire sur l’app et le site.  
                                            <Link to="/contact/"> Contactez-nous</Link> et nous verrons ce que l’on peut faire pour vous !</p>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="16">
                                    <Accordion.Header as="h3">📮 Êtes-vous sur les réseaux sociaux ?</Accordion.Header>
                                    <Accordion.Body>
                                        <p>Vous pouvez nous suivre sur Instagram où nous publions régulièrement de splendides photos de nos voyages ou de la communauté : 
                                            <a href={validateURL("https://instagram.com/rheaparks_app")}>@rheaparks_app</a></p>
                                        
                                        <p>Vous pouvez aussi suivre le développement de l’app sur Twitter <a href={validateURL("https://twitter.com/rheaparks_app")}>@rheaparks_app</a></p>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="17">
                                    <Accordion.Header as="h3">🏕 C’est quoi la suite ?</Accordion.Header>
                                    <Accordion.Body>
                                        <p>Tellement de choses ! On déborde d’idées. ✨</p>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </div>

                        <div className='bg-light-yellow py-5 text-center rounded-md'>
                            <div>
                                <h2>Vous avez une autre question en tête ?</h2>
                                <p>N'hésitez pas à nous contacter, nous répondrons dès que possible.</p>
                            </div>
                            <Button className="rounded-pill btn btn-orange" href="/contact/">Contacter</Button>
                        </div> 
                    </Col>
                </Row>
            </Container>
        </Layout>
    )
}